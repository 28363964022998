import React from 'react';
import Box from 'common/src/components/Box';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import VisitorSectionWrapper, {
  EmailInputWrapper,
  NameInputWrapper,
  WebsiteInputWrapper,
  InputWrapperDefault,
  TextareaInputWrapper,
  CheckboxWrapper,
  RepsInputWrapper,
} from './contactForm.style';
import Input from 'common/src/components/Input';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Select from 'common/src/components/Select';

const options = [
  { label: '1 - 3 size sales team', value: '1-3' },
  { label: '4 - 10 size sales team', value: '4-10' },
  { label: '11 - 20 size sales team', value: '11-20' },
  { label: '20+ size sales team', value: '20+' },
];

const ContactForm = ({
  title,
  textArea,
  button,
  form,
  setForm,
  acceptedConditions,
  setAcceptedConditions,
}) => {
  return (
    <FeatureBlock
      title={title}
      description={
        <>
          <NameInputWrapper>
            <Input
              value="string"
              onChange={newValue => setForm({ ...form, name: newValue })}
              placeholder="Name"
              iconPosition="left"
              aria-label="name"
            />
          </NameInputWrapper>
          <EmailInputWrapper>
            <Input
              value="string"
              onChange={newValue => setForm({ ...form, email: newValue })}
              inputType="email"
              placeholder="Email Address"
              iconPosition="left"
              aria-label="email"
            />
          </EmailInputWrapper>
          <WebsiteInputWrapper>
            <Input
              value="string"
              onChange={newValue => setForm({ ...form, url: newValue })}
              placeholder="Company website"
              iconPosition="left"
              aria-label="website"
            />
          </WebsiteInputWrapper>
          {/* <RepsInputWrapper>
            <Select
              value={form.reps}
              onChange={newValue => setForm({ ...form, reps: newValue })}
              placeholder="Sales team size"
              aria-label="salesTeamSize"
              options={options}
            />
          </RepsInputWrapper> */}
          <TextareaInputWrapper>
            <Input
              value="string"
              inputType="textarea"
              onChange={newValue => setForm({ ...form, message: newValue })}
              placeholder="Anything you would like to share with us?"
              aria-label="message"
            />
          </TextareaInputWrapper>
          <CheckboxWrapper>
            <FormControlLabel
              style={{ fontSize: '0.7rem' }}
              control={
                <Checkbox
                  checked={acceptedConditions}
                  name="conditions"
                  color="primary"
                  onChange={() => {
                    setAcceptedConditions(!acceptedConditions);
                  }}
                  size="small"
                  required={true}
                />
              }
              label={
                <span>
                  I agree to{' '}
                  <span
                    onClick={() => window.open('/termsAndConditions')}
                    style={{ textDecoration: 'underline' }}
                  >
                    Terms and Conditions
                  </span>{' '}
                  &amp;{' '}
                  <span
                    onClick={() => window.open('/privacy')}
                    style={{ textDecoration: 'underline' }}
                  >
                    Privacy policy.
                  </span>
                  <br />
                  <span style={{ fontSize: '11px' }}>
                    I consent that SalesOcean collects and use my personal data
                    for marketing related activities.
                  </span>
                </span>
              }
            />
          </CheckboxWrapper>
        </>
      }
      button={<CheckboxWrapper>{button} </CheckboxWrapper>}
    />
  );
};

ContactForm.defaultProps = {
  textArea: {
    width: ['100%', '100%', '55%'],
    ml: [0, 0, '58%'],
  },
};

export default ContactForm;
